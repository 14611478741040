<template>
  <div id="app">
    <Counter></Counter>
  </div>
</template>

<script>
import Counter from './components/Conter.vue'

export default {
  name: 'app',
  components: {
    Counter
  }
}
</script>

<style>
</style>
